import clsx from 'clsx'
import styles from './customers-marquee.css?url'
import { CustomerLogos } from '~/config/customers'
import { LinksFunction } from '@remix-run/node'

export const customersMarqueeLinks: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
]

export const CustomersMarquee = () => (
  <section className="marquee full-width">
    <div className="marquee-content">
      {CustomerLogos.map((customer) => {
        return (
          <div
            className={clsx([
              'logo-image',
              customer.size && `size-${customer.size}`,
            ])}
            key={customer.name}
          >
            <img
              src={`/images/customer-logos/${customer.image}`}
              alt={customer.name}
            />
          </div>
        )
      })}
    </div>

    <div className="marquee-content" aria-hidden="true">
      {CustomerLogos.map((customer) => {
        return (
          <div className="logo-image" key={`${customer.name}-2`}>
            <img
              src={`/images/customer-logos/${customer.image}`}
              alt={customer.name}
            />
          </div>
        )
      })}
    </div>
  </section>
)
